html, body {
  padding: 0;
  margin: 0;
  font-family: 'EB Garamond', serif;
  font-size: 18px;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1.5rem;
  line-height: 1;
}
h1 {
  font-size: 4.209rem;
}
h2 {
  font-size: 3.157rem;
}
h3 {
  font-size: 2.369rem;
}
h4 {
  font-size: 1.777rem;
}
h5 {
  font-size: 1.333rem;
}
h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1.5rem;
  line-height: 1.35;
}

a {
  color: inherit;
  font-style: italic;
  text-underline-offset: -.5rem;
  text-decoration-thickness: .5rem;
  text-decoration-color: rgba(245,250,0,.4);
  text-decoration-skip-ink: none;
  transition: 1s;
}
a:hover,
a:active {
  text-underline-offset: -1.5rem;
  text-decoration-thickness: 1.25rem;
  transition: 1s;
}

mark {
  background-color: rgba(245,250,0,.4);
  padding: 0 .25rem;
  color: inherit;
}

.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-bottom: 5rem;
}
.navbar-button {
  font-size: 1rem;
  display: flex;
  background-color: transparent;
  border: none;
  border-radius: .25rem;
  padding: 1rem;
  cursor: pointer;
}
.navbar-button:hover,
.navbar-button:active {
  background-color: rgba(0, 0, 0, .05);
}

.brand {
  font-style: normal;
  text-decoration: none;
}
.brand-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  text-transform: lowercase;
}
.brand-subtitle {
  font-size: 1.1rem;
  font-style: italic;
  text-align: center;
  text-decoration: none;
}

.main {
  position: relative;
  max-width: 100%;
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
}

.side {
  margin-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.side h1 {
  text-transform: lowercase;
  font-weight: 800;
  margin: 0;
  font-size: 2.369rem;
}
.side .author-figure {
  display: none;
}

.meta {
  font-size: 1rem;
  margin-top: 3rem;
  flex-direction: column;
  display: flex;
}

.content {
  font-size: 1.333rem;
  word-break: break-word;
  hyphens: manual;
}
.content > p {
  margin-top: 0;
  margin-bottom: 2.25rem;
}
.content > p:last-child {
  margin-bottom: 0;
}
.content > img {
  max-width: 100%;
  border-radius: 0 .5rem;
  margin-bottom: 2.25rem;
}
.content > img:last-child {
  margin-bottom: 0;
}
.content-extra {
  width: 100%;
  font-style: italic;
}
.content-aside {
  display: none;
  position: absolute;
  left: 0;
  font-size: 1rem;
  text-align: right;
}

.footer {
  display: grid;
  grid-template: "menu menu" "nav-l nav-r" / 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
  margin-top: 5rem;
}

.footer-menu {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
  grid-area: menu;
}
.home .footer-menu {
  display: flex;
}
.footer-menu a {
  font-style: initial;
  text-decoration: none;
  text-underline-offset: initial;
  text-decoration-thickness: initial;
}
.footer-menu a:hover {
  text-decoration: underline;
}

.footer-nav {
  display: flex;
  align-items: center;
  gap: .5rem;
  text-align: right;
  line-height: 1;
  font-style: initial;
  text-decoration: none;
  grid-area: nav-l;
}

.footer-menu ~ .footer-nav {
  text-align: left;
  justify-content: end;
  grid-area: nav-r;
}

.footer-nav-title {
  text-transform: lowercase;
  font-size: 1.2rem;
}

.footer-nav-subtitle {
  text-transform: lowercase;
  font-style: italic;
}

.sidebar-wrapper, .searchbar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  transition: .2s;
  opacity: 0;
  visibility: hidden;
  pointer-events: visible;
  display: flex;
  cursor: pointer;
  z-index: 5;
}
.sidebar {
  left: -20rem;
  width: 11rem;
  padding: 1rem 2rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: fixed;
  height: 100%;
  transition: .2s;
  cursor: auto;
}
.sidebar-close {
  margin: auto auto 2rem;
}

.searchbar {
  position: fixed;
  top: -5rem;
  width: calc(100% - 4rem);
  padding: 1rem 2rem;
  background-color: white;
  display: flex;
  height: 3rem;
  transition: .2s;
  cursor: auto;
}
.searchbar-field {
  width: 100%;
  border: none;
  font-size: 1rem;
  font-family: 'EB Garamond', serif;
}
.searchbar-field:focus {
  outline: none;
}
.searchbar-field::placeholder {
  font-style: italic;
}

.wrapper {
  position: relative;
  top: 0;
  left: 0;
  transition: .2s;
}

body.sidebar-open,
body.sidebar-open > #root,
body.searchbar-open,
body.searchbar-open > #root {
  overflow: hidden;
}
body.sidebar-open .wrapper {
  left: 20rem;
}
body.searchbar-open .wrapper {
  top: 5rem;
}
body.sidebar-open .sidebar-wrapper,
body.searchbar-open .searchbar-wrapper {
  opacity: 1;
  visibility: visible;
}
body.sidebar-open .sidebar {
  left: 0;
}
body.searchbar-open .searchbar {
  top: 0;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.sidebar-nav-item {
  display: block;
  text-align: center;
}

.toc {
  padding: 0;
  list-style: none;
  margin-bottom: 5rem;
}
.toc-title {
  font-size: 1.777rem;
  text-transform: lowercase;
}
.toc-row {
  display: flex;
  justify-content: space-between;
}
.toc-link {
  margin-right: 1rem;
  text-transform: lowercase;
}
.toc-dots {
  flex-grow: 1;
  height: 1.777rem;
  border-bottom: 1px dashed black;
}
.toc-page {
  width: 2rem;
  text-align: right;
  margin-left: 1rem;
}

.author-figure {
  margin: 0;
}
.author-image {
  width: 100%;
  border-radius: 0 .5rem;
}
.author-figcaption {
  padding: 0 .5rem;
  text-align: right;
}

.screenreader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.align-right {
  text-align: right;
}

.font-italic {
  font-style: italic;
}

.content-small, p.content-small {
  font-size: 1.333rem;
  margin-bottom: 1.25rem;
}

.width-100 {
  width: 100%;
}

.fixed-nav {
  margin-bottom: 9.167rem;
}
.fixed-nav .footer {
  position: fixed;
  width: calc(100% - 2rem);
  background-color: #fff;
  z-index: 1;
  bottom: 0;
}

@media (min-width: 500px) {
  .footer-menu {
    display: flex;
  }
  .footer-nav {
    gap: 1rem;
  }
}

@media (min-width: 600px) {
  .navbar {
    padding: 1rem 2rem;
  }

  .sidebar {
    width: 16rem;
  }

  .main {
    max-width: 100%;
    padding: 1rem 2rem;
    gap: 3rem;
    flex-direction: row;
  }
  
  .side {
    width: 20%;
    align-items: flex-end;
  }
  .side h1 {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    font-size: 3.157rem;
  }
  .side .author-figure {
    display: block;
  }

  .meta {
    text-align: right;
  }
  
  .content {
    padding-top: 7rem;
    font-size: 1.333rem;
    width: 100%;
  }
  .content-aside {
    display: block;
    margin-left: 2rem;
    width: calc(20% - 2rem);
  }
  .content-extra {
    margin-top: 3rem;
  }

  .footer {
    padding: 1rem 2rem;
  }
  .footer-nav {
    gap: 2rem;
  }
  .fixed-nav .footer {
    width: calc(100% - 4rem);
  }
}

@media (min-width: 900px) {
  .main {
    max-width: 100%;
    gap: 4rem;
  }
  
  .side {
    margin-left: 4%;
    width: 20%;
  }
  
  .content {
    font-size: 1.777rem;
    width: 68%;
  }
  .content-aside {
    margin-left: calc(4% + 2rem);
    width: calc(20% - 1rem);
  }
  .content-extra {
    width: 68%;
  }

  .footer {
    grid-template: "nav-l menu nav-r" / 1fr 2fr 1fr;
  }
}

@media (min-width: 1100px) {
  .main {
    max-width: 55rem;
    gap: 5rem;
  }
  
  .side {
    margin-left: 9%;
    width: 18%;
  }

  .side h1 {
    font-size: 4.209rem;
  }

  .meta {
    font-size: 1.333rem;
  }

  .content {
    width: 67%;
  }
  .content-aside {
    margin-left: calc(9% + 2rem);
    width: calc(27% - 6rem);
  }
  .content-extra {
    width: 67%;
  }
}